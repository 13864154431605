import Styles from "@/components/SearchForm/SearchForm.module.css";
import { advanceFillter, defaultForm } from "./constents";
import InputProps from "./components/InputProps";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacilityLocation,
  getMasterDataList,
  getPharmacyDataList,
  getSearchProviders,
  postDataList,
  setUpdateAllFormField,
  setUpdateFormField,
} from "@/store/slices/filterSlice";
import { useRouter } from "next/router";
import Loader from "../Loader";
import Link from "next/link";

const SearchForm = ({}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const filterConfig = useSelector((state) => state.filters);
  const {
    formSelected,
    formFields,
    globalLoader,
    masterData,
    listData: { limit, key },
  } = filterConfig;
  const defaultFormUI = defaultForm();
  const advanceFillterUI = advanceFillter();
  const [activeTabs, setActiveTabs] = useState(0);
  const [autocompleteOptions, setAutocompleteOptions] = useState({
    firstName: [],
    lastName: [],
    locationName: [],
  });
  const debounceTimerRef = useRef();

  useEffect(() => {
    if (router?.query?.key) {
      let key =
        router?.query?.key === "Doctor"
          ? 0
          : router?.query?.key === "Facility"
          ? 1
          : router?.query?.key === "Pharmacy"
          ? 2
          : 0;
      setActiveTabs(key);
    }
  }, []);

  const releventQueryGenerate = (formList, key, formFieldsValue) => {
    var updatedObject = {};
    const allowColumn = [
      {
        key: "county",
        includes: [],
      },
      {
        key: "state",
        includes: ["county"],
      },
      {
        key: "city",
        includes: ["county", "state"],
      },
      {
        key: "zip_code",
        includes: ["county", "state", "city"],
      },
      {
        key: "location_name",
        includes: ["county", "state", "city", "zipcode"],
      },
      {
        key: "specialty_name",
        includes: ["county", "state", "city", "zipcode", "hospital"],
      },
    ];
    for (const objectkey in formFieldsValue) {
      if (
        formFieldsValue.hasOwnProperty(objectkey) &&
        allowColumn?.filter(
          (n) => n?.key === key && n?.includes?.includes(objectkey),
        )?.length
      ) {
        if (formFieldsValue[objectkey]) {
          let modifiedKey = formList?.find((n) => n?.name === objectkey)
            ? formList?.find((n) => n?.name === objectkey)?.column
            : "";
          if (modifiedKey) {
            updatedObject[modifiedKey] = formFieldsValue[objectkey];
          }
        }
      }
    }
    let queryCreate = ``;
    for (const column in updatedObject) {
      queryCreate =
        queryCreate +
        ` AND Lower(${column}) = Lower('${updatedObject[column]}')`;
    }
    return queryCreate;
  };

  const handleSubmiForm = async (e, key, searchType) => {
    e.preventDefault();
    const formElements = e.target.elements;
    var modifledObject = {
      searchType: searchType === "advancefillter" ? "AdvanceFilter" : false,
    };

    if (searchType === "advancefillter") {
      modifledObject["selectFor"] =
        key === 0
          ? "Doctor"
          : key === 1
          ? "Facility"
          : key === 2
          ? "Pharmacy"
          : "";
    }
    for (let li = 0; li < formElements?.length; li++) {
      if (["input", "select"]?.includes(formElements[li]?.localName)) {
        if (
          formElements[li]?.name &&
          !formElements[li]?.value &&
          formElements[li]?.required
        ) {
          let applyStyle = document?.getElementsByClassName(
            formElements[li]?.className,
          )[formElements[li]?.name]
            ? document?.getElementsByClassName(formElements[li]?.className)[
                formElements[li]?.name
              ]
            : "";
          if (applyStyle) {
            toast.error(`${formElements[li]?.name} required field!`);
          }
          break;
        }
      }
      if (formElements[li]?.name && formElements[li]?.value) {
        modifledObject[formElements[li]?.name] = formElements[li]?.value;
      }
    }
    dispatch(setUpdateAllFormField(modifledObject));
    if (modifledObject?.selectFor === "Pharmacy") {
      dispatch(
        getPharmacyDataList({
          page: 0,
          limit: limit ? limit : 10,
          ...modifledObject,
        }),
      ).then((res) => {
        if (res?.type === "filters/getPharmacyDataList/fulfilled") {
          if (res?.payload?.data?.length) {
            router.push("/searchresult");
          } else {
            toast.error("Search result not found!");
          }
        }
      });
    } else {
      dispatch(
        postDataList({ page: 0, limit: limit ? limit : 10, modifledObject }),
      ).then((res) => {
        if (res?.type === "filters/postDataList/fulfilled") {
          if (res?.payload?.list?.length) {
            router.push("/searchresult");
          } else {
            toast.error("Search result not found!");
          }
        }
      });
    }
  };

  const getInputLabel = (n) => {
    return n.name == "keyword"
      ? formFields.selectFor == "Doctor"
        ? "Doctor's Last Name"
        : formFields.selectFor == "Facility"
        ? "Facility Name"
        : n.label
      : n.label;
  };

  const getInputMessages = (n) => {
    return n.name == "keyword"
      ? formFields.selectFor == "Doctor"
        ? "Enter doctor's last name"
        : formFields.selectFor == "Facility"
        ? "Enter the facility name"
        : n.messages
      : n.messages;
  };

  const onSearch = (data) => {
    const { key, value } = data;

    clearTimeout(debounceTimerRef.current);
    if (typeof value == "string") {
      if (value?.trim()?.length > 2) {
        debounceTimerRef.current = setTimeout(() => {
          if (key != "locationName") {
            searchProviders(data);
          } else {
            searchFacility(data);
          }
        }, 400);
      } else {
        setAutocompleteOptions((state) => {
          const clone = { ...state };
          clone[key] = [];
          return clone;
        });
      }
    } else if (value == undefined) {
      setAutocompleteOptions((state) => {
        const clone = { ...state };
        clone[key] = [];
        return clone;
      });
    }
  };

  const searchProviders = ({ key, value }) => {
    const data = {
      body: { [key]: value.trim() },
    };

    dispatch(getSearchProviders(data))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setAutocompleteOptions((state) => {
          const clone = { ...state };
          if (key == "lastName") {
            clone[key] = [
              ...new Set(res.modifiedList.map((el) => el.last_name)),
            ];
          } else if (key == "firstName") {
            clone[key] = [
              ...new Set(res.modifiedList.map((el) => el.first_name)),
            ];
          }
          return clone;
        });
      })
      .catch((e) => {});
  };

  const searchFacility = ({ key, value }) => {
    const data = {
      body: { [key]: value.trim() },
      params: { location: value, page: 1, limit: 50 },
    };

    dispatch(getFacilityLocation(data))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setAutocompleteOptions((state) => {
          const clone = { ...state };
          if (key == "locationName") {
            clone[key] = [
              ...new Set(res.facilities.map((el) => el.locationName)),
            ];
          }
          return clone;
        });
      })
      .catch((e) => {});
  };

  return (
    <div
      className="container-fluid"
      style={{ padding: "15px" }}
    >
      <div className="container mobilereduce">
        {defaultFormUI && !formSelected ? (
          <div
            className={`row justify-content-center ${Styles.searchContainer}`}
            style={{ position: "relative" }}
          >
            <div className="col-12 mt-2 mb-2 mobilereduce">
              <h1 className={Styles.heading}>{defaultFormUI?.heading}</h1>
            </div>
            <div className="col-12 mt-2 mb-2 mobilereduce">
              {defaultFormUI?.formFields?.length ? (
                <form
                  className={`row justify-content-between ${Styles.formContainer}`}
                  style={{ padding: "15px 0px" }}
                  onSubmit={(e) => {
                    handleSubmiForm(e, activeTabs, formSelected);
                  }}
                >
                  {defaultFormUI?.formFields?.map((n, i) =>
                    n.name == "keyword" &&
                    !formFields.selectFor ? null : n?.type === "select" ||
                      n?.type === "text" ||
                      n?.type === "submit" ? (
                      (n?.refKey === "selectCSCType" &&
                        n?.name === formFields["selectCSCType"]) ||
                      !n?.refKey ? (
                        <InputProps
                          {...n}
                          label={getInputLabel(n)}
                          messages={getInputMessages(n)}
                          index={i}
                          key={i}
                          loader={n?.column === key ? true : false}
                          onHandleClearField={(e) => {
                            dispatch(
                              setUpdateFormField({ key: n?.name, value: "" }),
                            );
                          }}
                          formObjectValue={formFields}
                          valueitem={
                            formFields[n?.name] ? formFields[n?.name] : ""
                          }
                          onHandleMenuOpen={(e) => {
                            if (n?.name === "county") {
                              dispatch(
                                setUpdateFormField({ key: "city", value: "" }),
                              );
                              dispatch(
                                setUpdateFormField({
                                  key: "zipcode",
                                  value: "",
                                }),
                              );
                            }
                            if (n?.name === "city") {
                              dispatch(
                                setUpdateFormField({
                                  key: "zipcode",
                                  value: "",
                                }),
                              );
                              dispatch(
                                setUpdateFormField({
                                  key: "county",
                                  value: "",
                                }),
                              );
                            }
                            if (n?.name === "zipcode") {
                              dispatch(
                                setUpdateFormField({
                                  key: "county",
                                  value: "",
                                }),
                              );
                              dispatch(
                                setUpdateFormField({ key: "city", value: "" }),
                              );
                            }
                            if (n?.column) {
                              let payload = {
                                key: n?.column ? n?.column : "",
                                value: e,
                                releventQuery: releventQueryGenerate(
                                  defaultFormUI?.formFields?.filter(
                                    (np) => np?.column,
                                  ),
                                  n?.column,
                                  formFields,
                                ),
                                masterListVar: n?.fetchFrom ? n?.fetchFrom : "",
                                searchFor: activeTabs === 2 ? "pharmacy" : "",
                              };
                              dispatch(getMasterDataList(payload));
                            }
                          }}
                          onChangeUpdate={(e) => {
                            dispatch(
                              setUpdateFormField({ key: n?.name, value: e }),
                            );
                          }}
                          options={
                            n?.fetchFrom ? masterData[n?.fetchFrom] : n?.options
                          }
                        />
                      ) : null
                    ) : null,
                  )}
                </form>
              ) : null}
            </div>
            {globalLoader ? <Loader /> : null}
          </div>
        ) : null}

        {formSelected === "advancefillter" ? (
          <div className="row justify-content-center pt-2 pb-4">
            {advanceFillterUI?.heading ? (
              <h1 className={Styles.heading}>{advanceFillterUI?.heading}</h1>
            ) : null}
          </div>
        ) : null}
        {formSelected === "advancefillter" && advanceFillterUI?.tabs?.length ? (
          <div
            className={`row justify-content-center ${Styles.searchContainer}`}
            style={{ position: "relative" }}
          >
            <div
              className="col-12 mobilereduce"
              style={{ margin: "0px", padding: "0px 15px" }}
            >
              <div
                className={"row justify-content-start scrollablblview"}
                style={{ width: "100%", margin: "0px", display: "flex" }}
              >
                {advanceFillterUI?.tabs.map((item, index) => (
                  <button
                    onClick={(e) => {
                      setActiveTabs(index);
                    }}
                    key={index}
                    className={
                      Styles.tabfilter +
                      (activeTabs === index ? " " + Styles.tabFilterActive : "")
                    }
                  >
                    {item?.heading ? item?.heading : ""}
                  </button>
                ))}
              </div>
            </div>
            <div
              className="col-12 mobilereduce"
              style={{ padding: "0px 15px" }}
            >
              {advanceFillterUI?.tabs[activeTabs]?.formFields?.length ? (
                <form
                  className={`row justify-content-start ${Styles.formContainer} ${Styles.paneltabfilter2}`}
                  style={{
                    padding: "15px 0px 0px",
                    width: "100%",
                    margin: "0px",
                  }}
                  onSubmit={(e) => {
                    handleSubmiForm(e, activeTabs, formSelected);
                  }}
                >
                  {advanceFillterUI?.tabs[activeTabs]?.formFields?.map((n, i) =>
                    n?.type === "select" ||
                    n?.type === "text" ||
                    n?.type === "submit" ? (
                      <InputProps
                        {...n}
                        index={i}
                        key={i + n?.name}
                        is3view={true}
                        loader={n?.column === key ? true : false}
                        onHandleClearField={(e) => {
                          dispatch(
                            setUpdateFormField({ key: n?.name, value: "" }),
                          );
                        }}
                        formObjectValue={formFields}
                        valueitem={
                          formFields[n?.name] ? formFields[n?.name] : ""
                        }
                        onHandleMenuOpen={(e) => {
                          if (n?.column) {
                            let payload = {
                              key: n?.column ? n?.column : "",
                              value: e,
                              releventQuery: releventQueryGenerate(
                                advanceFillterUI?.tabs[
                                  activeTabs
                                ]?.formFields?.filter((np) => np?.column),
                                n?.column,
                                formFields,
                              ),
                              masterListVar: n?.fetchFrom ? n?.fetchFrom : "",
                              searchFor: activeTabs === 2 ? "pharmacy" : "",
                            };
                            dispatch(getMasterDataList(payload));
                          }
                        }}
                        onChangeUpdate={(e) => {
                          console.log("[onChangeUpdate]", e, n?.name);
                          if (n?.autoComplete) {
                            onSearch({ key: n?.name, value: e });
                          }
                          dispatch(
                            setUpdateFormField({ key: n?.name, value: e }),
                          );
                        }}
                        options={
                          n?.fetchFrom
                            ? masterData[n?.fetchFrom]
                            : autocompleteOptions[n?.name]?.length
                            ? autocompleteOptions[n?.name]
                            : n?.options
                        }
                      />
                    ) : null,
                  )}
                </form>
              ) : null}
            </div>
            {globalLoader ? <Loader /> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchForm;
